.testimonial-wrapper {
    padding: 48px;
    background-image: url('../images/hero-inner-v1-no-photo-vector.svg');
    background-position: 350px 150px;
    background-repeat: no-repeat;


    @media (max-width: 768px) {
        background-position: 100px 440px;
    }

    .testimonial-img-wrap {
        padding: 20px;
        background-image: url('../images/testimonial-red-pattern.svg');
        background-size: contain;
        background-repeat: no-repeat;

        img {
            aspect-ratio: 1 / 1;
            object-fit: cover;
        }
    }

   
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &.testimonial-heading {
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
            color: $primary;
            margin-bottom: calc(1.5rem + 1.375rem);
            position: relative;
            @media (max-width: 768px) {
                text-align: center;
                &::after {
                    transform: translate(-50%, 50%) skewX(-30deg) !important;
                    left: 50% !important;
                    bottom: -1.5rem;
                }
            }

            &::after {
                content: "";
                width: 1.875rem;
                height: .25rem;
                background-color: $grey-40;
                transform: skewX(-30deg);
                display: block;
                position: absolute;
                left: 0;
                bottom: -1.125rem;
            }
        }
    }

    .testimonial-user {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin: 24px 0 0;
        @media (max-width: 768px) {
            text-align: center;
        }
    }

    .testimonial-user-details {
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        @media (max-width: 768px) {
            text-align: center;
        }
    }
}