.programMetadata-wrapper {
    padding: 32px 48px;
    display: flex;
    flex-direction: row;
    gap: 15px;

    @media (max-width: 768px) {
        border-left: 5px solid #17438D;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 32px 24px;
    }

    .programMetadata-col {
        flex: 1;

        .intro-text {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1.5rem;

            @media (max-width: 768px) {
                font-size: 1.125rem;
                line-height: 1.375rem;
            }
        }

        .programMetadata-body {
            font-size: .9375rem;
            font-weight: 400;
            line-height: 1.5rem;
        }
    }
}