.btn {
    text-align: left;
}

.btn-arrow-rt {
    position: relative;
    padding-right: 40px;

    /* Adjust padding to make space for the icon */

    &::after {
        content: "\F135"; // Unicode value for "arrow-right" icon in Bootstrap Icons
        font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
        vertical-align: -0.125em;
        margin-left: 8px;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        position: absolute;
        right: -2px;
        top: 50%;
        font-size: 28px;
        transition: 0.3s;
    }

    &.btn-lg {
        &::after {
            font-size: 32px;
        }
    }

    /* Hover Effect */
    &:hover::after, &:focus::after {
        transform: translate(-30%, -50%); /* Move 5px to the right on hover */
    }

}

.btn-light {
    background-color: $white;
    border-color: $white;
}

.btn-outline-light {
    color: $white;
    border-color: $white;
}

.btn-outline-light:hover {
    background-color: $white;
    border-color: $white;
}

.btn-outline-primary {

    background-color: white;

    &:hover {
        border-color: $blue-400;
        color: $blue-400;
        background-color: white;
    }

}

.btn-fill-primary-dark-blue {
    background-color: $blue-900;
}

.btn-primary {
    border-color: $blue-400;

    &:hover {
        background-color: $blue-400;
        border-color: $blue-400;
    }
}

.btn-white {
    background: $white;
    color: $secondary;
    border-color: $white;
    font-weight: 500;

    &:hover {
        background: $secondary;
        color: $white;
        border-color: $secondary;
    }
}

// Collapsible Sidebar
.btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border: 0;

    &:hover,
    &:focus {
        color: rgba(0, 0, 0, .85);
        background-color: $light;
    }

    &::before {
        width: 1.25em;
        line-height: 0;
        content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
        transition: transform .35s ease;
        transform-origin: .5em 50%;
    }

    &[aria-expanded="true"] {
        color: rgba(0, 0, 0, .85);
    }

    &[aria-expanded="true"]::before {
        transform: rotate(90deg);
    }
}

.btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;

    &:hover,
    &:focus {
        background-color: $light;
    }
}

/* inline buttons */
.inline-buttons {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: .75rem;
    flex-wrap: wrap;

    &.inline-buttons-center {
        justify-content: center;
        width: 100%;
        margin: 0 auto;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
}