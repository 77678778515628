.breadcrumb {
    .breadcrumb-item {
        a {
            text-decoration: none;
            &:hover, &:focus {
                color: $blue-400;
                text-decoration: underline;
            }
        }
        &:first-child {
            a {
                position: relative;
                text-indent: -9999px; /* Hide the text */
                display: inline-block; /* Allows the link to be sized by its content */
                margin-right: 18px;
                &::before {
                    content: "\F422"; // Unicode value for "arrow-right" icon in Bootstrap Icons
                    font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 16px; /* Icon width */
                    height: 16px; /* Icon height */
                    text-indent: 0;
                }
                &:hover, &:focus {
                    color: $blue-400;
                }
            }
        }
    }
}

.bg-primary, .bg-secondary {
    .breadcrumb {
        .breadcrumb-item {
            &.active {
                color: $white;
            }
            a {
                color: $white;
                &:hover, &:focus {
                    color: $white;
                }
            }
            &:first-child {
                a {
                    &:hover, &:focus {
                        color: $white;
                    }
                }
            }
            &::before {
                color: $white;
            }
        }
    }
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "\F285"; // Unicode value for "arrow-right" icon in Bootstrap Icons
    font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
    scale: .6;
}