body {
    font-size: .9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    font-family: $font-family-base;
}

.bg-primary,
.bg-secondary {
    color: $white;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    &.intro-text {
        position: relative;
        margin-bottom: calc(1.5rem + 1.375rem);

        &::after {
            content: "";
            width: 1.875rem;
            height: .25rem;
            background-color: $grey-40;
            transform: skewX(-30deg);
            display: block;
            position: absolute;
            left: 0;
            bottom: -1.125rem;
        }
        &-center {
            text-align: center;
            &::after {
                transform: translate(-50%, 50%) skewX(-30deg);
                left: 50% !important;
                bottom: -1.5rem;
            }
        }
    }
}

.bg-primary,
.bg-secondary {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $white !important;

        &.intro-text {
            position: relative;
            margin-bottom: calc(.75rem + .75rem);

            &::after {
                content: "";
                width: 1.875rem;
                height: .25rem;
                background-color: rgba(255, 255, 255, 0.2);
                transform: skewX(-30deg);
                display: block;
                position: absolute;
                left: 0;
                bottom: -.75rem;
                transition: 0.3s;
            }
        }
    }
}

h1 {
    font-size: 4rem;
    font-style: normal;
    font-weight: 900;
    line-height: 4rem;
    letter-spacing: -0.08rem;

    @media (max-width: 768px) {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }
}

h2 {
    font-size: 3rem;
    font-style: normal;
    font-weight: 900;
    line-height: 3.25rem;
    letter-spacing: -0.96px;

    @media (max-width: 768px) {
        font-size: 2.3rem;
        line-height: 2.3rem;
    }
}

h3 {
    font-size: 2rem;
    font-style: normal;
    font-weight: 900;
    line-height: 2.25rem;
    letter-spacing: -0.64px;
}

h4 {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem;
}

h5 {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
}

h6 {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
}

.bg-light {
    color: initial !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        color: $secondary;
    }
}