.swiper {
    width: 100%;
    height: 100%;
    padding: 30px 0 0 !important;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-free-mode>.swiper-wrapper {
    margin-bottom: 75px !important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    border-radius: 0;
    width: 20px;
    height: 6px;
    transform: skewX(-30deg);
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 25px !important;
}

/* Grid layout for desktop to ensure all six items are fully visible */
@media (min-width: 1400px) {
    .mySwiper {
        display: flex;
        flex-wrap: nowrap;
        /* Prevent wrapping to a new line */
        gap: 20px;
        /* Adjust to match Swiper's spaceBetween value */
    }

    .mySwiper .swiper-slide {
        flex: 0 0 calc(100% / 6 - 20px);
        /* Divide by 6 and account for gap */
        max-width: calc(100% / 6 - 20px);
        /* Ensures six items fit within the viewport */
        box-sizing: border-box;
        height: auto !important;
    }

    /* New rules for mySwiperSecondary */
    .mySwiperSecondary {
        display: flex;
        flex-wrap: nowrap;
        gap: 20px;
        /* Adjust to match Swiper's spaceBetween value */
    }

    .mySwiperSecondary .swiper-slide {
        flex: 0 0 calc(100% / 4 - 20px);
        /* Divide by 4 for four items */
        max-width: calc(100% / 4 - 20px);
        box-sizing: border-box;
        height: auto !important;
    }
}

/* Swiper layout for mobile */
@media (max-width: 1399px) {
    .mySwiper,
    .mySwiperSecondary {
        display: block;
        /* Revert to Swiper's layout */
    }

    .swiper {
        width: calc(100% + 30px);
        margin-left: -15px !important;
        padding: 30px 15px 0 !important;
    }
}

/* Ensure Swiper works properly within Bootstrap row */
.row .swiper {
    display: block !important; /* Reset flexbox on Swiper */
    margin-left: 0 !important;
    margin-right: 0 !important;
    overflow: hidden;
    height: auto;
}

.row .swiper-wrapper {
    flex-wrap: nowrap !important; /* Prevent slide wrapping */
}

.row .swiper-slide {
    flex: 0 0 auto !important; /* Ensure slides maintain proper width */
}
