.footer-main {
    color: $white;

    a[href^="tel:"] {
        color: $white;
    }

    @media (max-width: 991px) {
        .col-lg {
            text-align: center;
        }
    }

    >.container>.row {
        padding: 64px 0;
    }

    .footer-bottom {
        background-color: $red-600;
        padding: 37px 0;
    
        .footer-bottom-container {
            display: flex;
            gap: 0 92px;
            align-items: center;
            flex-wrap: wrap; // Allow wrapping to the next line
    
            @media (max-width: 991px) {
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 16px;
            }
        }
    
        .footer-bottom-left-column {
            flex: 1;
            font-size: .75rem;
    
            @media (max-width: 991px) {
                width: 100%;
            }
        }
    
        .footer-bottom-right-column {
            flex-shrink: 0;
    
            @media (max-width: 991px) {
                width: 100%;
                order: 2;
            }
        }
    
        .footer-bottom-right-column img {
            display: block;
            max-width: 100%;
            margin: 0 auto;
            width: 100%;
        }
    
        .footer-bottom-new-div {
            width: 100%;
            flex-basis: 100%; // Ensure it takes full width on desktop
            order: 2;
    
            @media (max-width: 991px) {
                order: 1;
            }
        }
    
        .footer-bottom-tech {
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-direction: row;
            gap: .75rem;
            margin-top: .75rem;
    
            @media (max-width: 991px) {
                flex-direction: column;
                text-align: center;
                gap: .75rem;
            }
    
            li {
                a {
                    font-size: 12px;
                    color: $white;
                    text-decoration: underline;
                }
            }
        }
    }
    


    .footer-contact-info {
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 32px 0;
    }

    .footer-social-media {
        list-style-type: none;
        padding: 0;
        display: flex;
        gap: 16px;
        margin: 32px 0;

        a {
            color: $white;
            font-size: 18px;
        }

        @media (max-width: 991px) {
            width: fit-content;
            margin: 32px auto;
            a {
                font-size: 24px;
            }
        }
    }
    .btn-donate {
        display: flex;
        width: fit-content;
        &::before {
            content: "";
            width: 1rem;
            height: 1rem;
            margin-right: .5rem;
            background-image: url('../images/Giving_Hand.svg');
            background-position: center left;
            background-size: cover;
        }
        &:hover {
            &::before {
                background-position: center right;
            }
        }
        @media (max-width: 991px) {
            margin: auto;
        }
    }
}

.heading-footer {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: #ffffff;
    font-family: $headings-font-family;
    position: relative;
    margin-bottom: 3rem;
    font-weight: 600;

    @media (max-width: 991px) {
        text-align: center;
    }

    &::after {
        position: absolute;
        content: '';
        width: 1.875rem;
        height: .25rem;
        background-color: $red-600;
        left: 0;
        bottom: -1rem;
        transform: skewX(-30deg);

        @media (max-width: 991px) {
            transform: translate(-50%, -50%);
            left: 50%;
        }
    }
}

.link-footer {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: .75rem;

    @media (max-width: 991px) {
        text-align: center;
        margin-bottom: 2.5rem;
    }

    li {
        a {
            text-decoration: none;
            font-size: .9375rem;
            line-height: 1.5rem;
            font-family: $font-family-base;
            color: #ffffff;
            display: block;
            text-underline-offset: .25rem;

            &:hover,
            &:focus {
                text-decoration: underline;
            }

            &.footer-link-main-campus {
                position: relative;
                margin-bottom: 1rem;

                &::after {
                    content: 'Main Campus';
                    position: absolute;
                    left: 0;
                    top: 100%;
                    font-size: .75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;

                    @media (max-width: 991px) {
                        transform: translate(-50%, -50%);
                        left: 50% !important;
                        margin-top: .5rem;
                    }
                }
            }

            &.footer-link-branch-campus {
                position: relative;
                margin-bottom: 1rem;

                &::after {
                    content: 'Branch Campus';
                    position: absolute;
                    left: 0;
                    top: 100%;
                    font-size: .75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;

                    @media (max-width: 991px) {
                        transform: translate(-50%, -50%);
                        left: 50% !important;
                        margin-top: .5rem;
                    }
                }
            }

            &.footer-link-health-sciences {
                position: relative;
                margin-bottom: 1rem;

                &::after {
                    content: 'Health Sciences';
                    position: absolute;
                    left: 0;
                    top: 100%;
                    font-size: .75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;

                    @media (max-width: 991px) {
                        transform: translate(-50%, -50%);
                        left: 50% !important;
                        margin-top: .5rem;
                    }
                }
            }
            &.footer-link-clinton {
                position: relative;
                margin-bottom: 1rem;

                &::after {
                    content: 'Clinton';
                    position: absolute;
                    left: 0;
                    top: 100%;
                    font-size: .75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1rem;

                    @media (max-width: 991px) {
                        transform: translate(-50%, -50%);
                        left: 50% !important;
                        margin-top: .5rem;
                    }
                }
            }
        }
    }
}