.video-embed-wrapper {
    position: relative;
    margin-top: 90px;
    &::before {
        content: '';
        width: 515px;
        height: 50px;
        background-image: url('../images/red-accent.svg');
        background-size: cover;
        position: absolute;
        background-position: center top;
        top: -47px;
        right: 0;

        @media (max-width: 768px) {
            width: auto;
            left: 0;
            right: 0;
            background-position: -70px top;
        }
    }
}