.start-here-bg {
  margin: 100px 0;
  padding: 64px 0;
  position: relative;

  @media (max-width: 768px) {
    margin: 100px 0 0;
  }

  .start-here-wrap {
    position: relative;

    .start-here-column {
      @media (min-width: 769px) {
        width: 50%;
      }

      .start-here-img {
        @media (min-width: 769px) {
          position: absolute;
          right: 0;
          top: 45%;
          width: 55%;
          transform: translate(0%, -50%);
        }

        img {
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }

        .start-here-img-relative {
          position: relative;

          &::after {
            @media (min-width: 769px) {
              content: '';
              width: 515px;
              height: 80px;
              background-image: url('../images/red-accent.svg');
              background-size: cover;
              position: absolute;
              background-position: bottom center;
              bottom: -77px;
              right: 0;
            }
          }
        }
      }

      .start-here-inline {
        list-style-type: none;
        padding: 0;
        display: inline-flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        @media (max-width: 768px) {
          gap: 12px;
          margin-top: -20px;
        }

        li {
          a {
            display: block;
            background-image: url('../images/lg-btn-bg.png');
            background-size: 250px;
            background-repeat: no-repeat;
            background-position: calc(100% + 150px) 75%;
            transition: 0.3s;
            &:hover, &:focus {
              background-position: calc(100% + 130px) 75%;
            }
          }
        }
      }
    }
  }

  .container::before {
    content: '';
    width: 515px;
    height: 80px;
    background-image: url('../images/red-accent.svg');
    background-size: cover;
    position: absolute;
    background-position: center top;
    top: -77px;
    left: 0;

    @media (max-width: 768px) {
      width: auto;
      left: 0;
      right: 0;
      background-position: -70px top;
    }
  }
}