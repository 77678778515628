.hero-gradient {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, black, transparent);
  }
}

/* Hero Image Inner */
.hero-inner {
  .hero-image-inner-container {
    position: relative;
    overflow: hidden;

    .hero-image-inner {
      position: relative;
      width: 100%;
      margin: auto;
      overflow: hidden;
      aspect-ratio: 16 / 9;
      padding-top: 2.625rem;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -95%;
        bottom: -1px;
        width: 100%;
        background-color: $grey-10;
        transform: skewX(-28deg);
        transform-origin: top left;
      }
    }

    &::before {
      content: '';
      position: absolute;
      right: 20px;
      bottom: 0;
      top: 94px;
      left: 0;
      background-image: url('../images/vector-hero-inner.png');
      z-index: 999;
      background-repeat: no-repeat;
      background-position: top right;
    }
  }

  &.hero-inner-nomask {
    .hero-image-inner-container {
      &::before {
        top: 66.6%;
      }

      .hero-image-inner {
        &::after {
          display: none;
        }
      }
    }
  }

  h1 {
    @media (max-width: 768px) {
      font-size: 3rem;
      line-height: 3.25rem;
    }
  }

  .image-container {
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  /* hero inner background image with overlay */
  &.hero-inner-tertiary {
    &.full-width-section {
      position: relative;
      background-image: url('../images/student_two.jpg');
      background-size: cover;
      background-position: center;


      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(11, 40, 80, 0.90);
        /* Dark overlay with 60% opacity */
      }

      .content-container {
        position: relative;
        z-index: 1;
        padding-top: 2rem;
        padding-bottom: 2rem;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: 385px;
          height: 150px;
          background-image: url('../images/hero-inner-top-level-red-pattern.png');
          background-size: contain;
          background-repeat: no-repeat;

          @media (max-width: 768px) {
            width: 270px;
            height: 107px;
            right: 30px;
          }
        }

        .content-body {
          padding: 8rem 0;

          @media (max-width: 768px) {
            padding: 4rem 0;
          }

          .lead {
            max-width: 800px;
            margin: auto;
            padding-bottom: 1rem;
          }
        }
      }
    }
  }

  &.hero-inner-secondary {
    .align-items-end {
      min-height: 300px;
      flex-grow: 1;
      position: relative;
      overflow: hidden;

      @media (max-width: 768px) {
        min-height: 160px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 20%;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url('../images/hero-inner-v1-no-photo-vector.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center bottom;

        @media (max-width: 768px) {
          top: 0;
          background-size: cover;
          background-position: right top;
        }
      }
    }

  }
}

/* image with overlay text */
.image-with-overlay {
  position: relative;
  padding: 10px;

  img {
    width: 100%;

    @media (max-width: 768px) {
      position: relative;
      z-index: -1;
    }
  }

  &.image-with-overlay-transparent {
    .bg-secondary {
      &.image-with-overlay-content {
        background-color: rgba(11, 40, 80, 0.88) !important;
      }
    }
  }

  &.image-with-overlay-pattern {
    .bg-secondary {
      &.image-with-overlay-content {
        background-image: url('../images/pattern-blue-opacity.png');
        background-size: 80px;
      }
    }
  }

  &::before {
    content: '';
    width: 515px;
    height: 50px;
    background-image: url('../images/red-accent.svg');
    background-size: cover;
    position: absolute;
    background-position: center top;
    top: -32px;
    right: 64px;
    z-index: -1;

    @media (max-width: 768px) {
      width: 350px;
      background-position: center top;
    }
  }

  &-content {
    padding: 2rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 2rem;
      line-height: 2.25rem;

      @media (max-width: 768px) {
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 1.75rem;
      }
    }

    @media (min-width: 769px) {
      max-width: 375px;
      position: absolute;
      left: -10px;
      bottom: 0;
    }

    @media (max-width: 768px) {
      margin-left: -10px;
      margin-right: 10px;
      margin-top: -20px;
    }

  }

  &.image-with-overlay-top-left {
    @media (min-width: 769px) {
      .image-with-overlay-content {
        left: -10px;
        top: 0;
        bottom: auto;
      }
    }
  }

  &.image-with-overlay-top-right {
    @media (min-width: 769px) {
      .image-with-overlay-content {
        right: -10px;
        left: auto;
        top: 0;
        bottom: auto;
        margin-right: 0 !important;
      }
    }
  }

  &.image-with-overlay-bottom-right {
    @media (min-width: 769px) {
      .image-with-overlay-content {
        right: -10px;
        left: auto;
        margin-right: 0 !important;
      }
    }
  }
}

/* call out box */
/* General Styles */
.call-out-two-column-section {
  width: 100%;
  padding: 2rem 0;

  /* Optional padding for spacing */
  &.call-out-right {
    .call-out-content-img {
      @media (min-width: 769px) {
        margin-right: -90px;
        position: relative;
        z-index: -1;
      }
    }

    @media (max-width: 768px) {
      .call-out-row {
        flex-direction: column;
      }
    }
  }

  &.call-out-left {
    .call-out-content-img {
      @media (min-width: 769px) {
        margin-left: -90px;
        position: relative;
        z-index: -1;
      }
    }
  }
}


.call-out-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Column Styles */
.call-out-column {
  flex: 1 1 50%;
  /* Takes up 50% of the width */
  display: flex;
  align-items: center;
  /* Vertically center content */
  justify-content: center;
  /* Horizontally center content */
  box-sizing: border-box;

  /* Ensure padding is included in width calculations */
  &:first-child {
    position: relative;

    .call-out-content-text {
      &::before {
        content: '';
        width: 515px;
        height: 250px;
        background-image: url('../images/red-accent.svg');
        background-size: cover;
        position: absolute;
        background-position: center top;
        top: -57px;
        left: 0;
        z-index: -2;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  &:last-child {
    position: relative;

    .call-out-content-text {
      &::before {
        content: '';
        width: 515px;
        height: 250px;
        background-image: url('../images/red-accent.svg');
        background-size: cover;
        position: absolute;
        background-position: center top;
        top: -57px;
        left: -170px;
        z-index: -2;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.call-out-left {

  .call-out-column:has(> .call-out-content-text:first-child) {
    @media (max-width: 768px) {
      margin-top: -32px;
      margin-right: 32px;
      z-index: 100;
    }
  }

  .call-out-column:has(> .call-out-content-img) {
    @media (max-width: 768px) {
      margin-left: 32px;
    }
  }

}

.call-out-right {

  .call-out-column:has(> .call-out-content-text:first-child) {
    @media (max-width: 768px) {
      margin-top: -32px;
      margin-left: 32px;
      z-index: 100;
    }
  }

  .call-out-column:has(> .call-out-content-img) {
    @media (max-width: 768px) {
      margin-right: 32px;
    }
  }

}

.call-out-content {
  &.call-out-content-text {
    padding: 3rem;
    background-color: $secondary;
    color: $white;
    position: relative;
  }
}

/* Image Styling */
.call-out-image-column img {
  width: 100%;
  height: auto;
  display: block;
}

/* Responsive Behavior */
@media (max-width: 768px) {
  .call-out-row {
    flex-direction: column-reverse;
    /* Stack columns vertically and reverse the order */
  }

  .call-out-column {
    flex: 1 1 100%;
    /* Columns take full width on mobile */
  }
}