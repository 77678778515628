// Custom styling for Slick Carousel dots
.mobile-carousel {
    .slick-dots {
        li {
            // Reset default styles if necessary
            margin: 0 4px;
            width: auto;
            height: auto;

            button {
                // Hide the default dot
                font-size: 0;
                line-height: 0;
                display: block;
                width: 16px; // Rectangle width
                height: 6px; // Rectangle height
                padding: 0;
                cursor: pointer;
                color: transparent;
                border: none;
                background: transparent;
                transform: skewX(-30deg);

                &:before {
                    // Override Slick's default styles
                    font-size: 0;
                    line-height: 0;

                    // Rectangle appearance
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: $grey-20; // Rectangle color

                    // Transition for the color change when active
                    transition: background-color .3s ease;
                }
            }

            &.slick-active button:before {
                // Active state styles
                background-color: $blue-400; // Active rectangle color
            }

            .slick-dot-icon {
                display: none;

            }
        }
    }

    // Custom styling for Slick Carousel slides
    .slick-slide {
        // Add gap between slides
        margin-right: 15px; // Adjust the gap size as needed
        transition: transform 0.3s ease;

        // If you want the gap to apply to both sides of the slide (left and right),
        // you can use margin on both sides for a symmetric look
    }

    // Ensure the Slick list container accounts for the added margin on the first and last slides
    .slick-list {
        // Add padding to the left and right to offset the margin of the first and last slide
        // so they align correctly at the edges
        padding: 30px 0;

        @media (min-width: 1400px) {
            margin-right: -15px;
        }
        @media (max-width: 1400px) {
            margin: 0 -15px;
            padding-left: 15px;
        }
    }
}

// Inside your main.scss or a separate _carousel.scss file

// Media query for viewports wider than 768px
@media (min-width: 1400px) {
    .mobile-carousel {

        .slick-list,
        .slick-track {
            transform: none !important; // Disable Slick's transforms
            transition: none !important; // Disable Slick's transitions
        }

        .slick-track {
            width: 100% !important;
            display: flex;

            .slick-slide {
                width: auto !important; // Reset the width of each slide
                height: auto !important; // Reset the height of each slide
                // Set the desired padding or margin between items here
                flex: 1;
            }
        }

        .slick-dots {
            display: none !important;
        }

        // Reset any other styles related to Slick that may affect layout
    }
}