.list-group-stacked {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;

  a.card-link {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      left: -12px;
      top: 1.25rem;
      bottom: 1.25rem;
      width: 2px;
      background-color: $secondary;
    }

    &:hover,
    &:focus {
      &::before {
        background-color: $primary;
      }
    }
  }

  li {
    border-bottom: solid 1px $grey-50;

    .card.card-primary {
      padding: 1.25rem 0;
    }

    &:first-child {
      .card.card-primary {
        padding-top: 0;
      }

      a.card-link {
        &::before {
          top: 0;
        }
      }
    }
  }
}

.sidebar-unordered-menu {
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  margin-bottom: 1.25rem;

  li {
    border-bottom: solid 1px $grey-30;

    a {
      padding: .75rem 0;
      text-decoration: none;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      display: block;

      &:hover {
        text-decoration: underline;
      }
      
    }
  }
}

.list-three-col {
  list-style-type: none;
  margin: 0;
  padding: 0;
  
  @include media-breakpoint-up(md) {
      columns: 3;
      -webkit-columns: 3;
      -moz-columns: 3;
  }
  @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
  }
  li {
      margin-bottom: 15px;
      @include media-breakpoint-down(md) {
          display: block;
          a {
              display: block;
              text-align: left;
          }
      }
  }
}