.flexibleInner-sidebar-toggle {
    .flexibleInner-sidebar-toggle-header {
        @media (min-width: 992px) {
            position: relative;
            margin-bottom: calc(1.5rem + 1.375rem);
            font-family: $headings-font-family;
            font-weight: 600;
            color: $secondary;
            line-height: 28px;
            font-size: 24px;

            &::after {
                content: "";
                width: 1.875rem;
                height: .25rem;
                background-color: $grey-40;
                transform: skewX(-30deg);
                display: block;
                position: absolute;
                left: 0;
                bottom: -1.125rem;
            }
        }

        @media (max-width: 991px) {
            padding: 12px 16px;
            position: relative;
            font-family: $headings-font-family;
            font-weight: 600;
            color: $white;
            background-color: $secondary;
            line-height: 24px;
            font-size: 20px;

            &::after {
                content: "-";
                font-size: 24px;
                position: absolute;
                right: 12px;
                top: 50%;
                color: $white;
                transform: translate(-50%, -50%);
            }
        }

        &.collapsed {
            @media (max-width: 991px) {
                &::after {
                    content: "+";
                }
            }
        }
    }

    .flexibleInner-sidebar-toggle-content {
        @media (max-width: 991px) {
            padding: 28px 16px;
            background-color: $grey-30;

            &.collapsed {
                display: none;
            }
        }
    }
}