table {
    thead {
        th {
            color: #090E16 !important;
            font-size: .8125rem;
            line-height: 1.25rem;
        }
    }

    th {
        font-weight: 500;
        color: $primary !important;
    }
}

.table-responsive, .table-responsive-md {
    @media screen and (max-width: 768px) {

        .table th,
        .table td {
            white-space: nowrap;
            /* Prevents text wrapping */
        }

    }
}