.card-event-link {
    text-decoration: none;
    width: 100%;

    .card.card.card-event {
        height: 100%;
        background-image: url('../images/events-bg.svg');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: bottom right;
        text-align: left;
    }

    &:hover,
    &:focus {
        .card {
            &.card-event {
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    background-color: $red-500;
                    top: 5px;
                    bottom: 0;
                    right: -10px;
                }

                &::before {
                    content: "";
                    position: absolute;
                    height: 10px;
                    background-color: $red-500;
                    bottom: -10px;
                    left: 10px;
                    right: -10px;
                }
            }
        }
    }
}

.card {
    &.card-event {
        min-height: 290px;

        &:hover time {
            color: $primary !important;
            text-shadow: none;
        }

        time {
            xcolor: $white !important;
            position: relative;
            padding-bottom: 20px;
            margin-bottom: 20px;
            display: block;
            xtext-shadow: 1px 1px 0 $primary, -1px -1px 0 $primary, 1px -1px 0 $primary, -1px 1px 0 $primary, 1px 1px 0 $primary;
            font-size: 50px;
            font-size: 3rem;
            line-height: 3.25rem;
            color: $primary !important;
            text-shadow: none;

            span {
                display: block;
                color: $secondary !important;
                text-shadow: none;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30px;
                height: 4px;
                background-color: $grey-40;
                transform: skewX(-30deg);
            }

        }

        .card-body {
            padding-bottom: 70px;
            padding-top: 20px;

            .card-text {
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.5rem;
                letter-spacing: -0.0225rem;
            }
        }

        &:hover,
        &:focus-within {

            &::after {
                content: "";
                position: absolute;
                width: 10px;
                background-color: $red-500;
                top: 5px;
                bottom: 0;
                right: -10px;
                background-image: url('../images/rectangle_11.png');
            }

            &::before {
                content: "";
                position: absolute;
                height: 10px;
                background-color: $red-500;
                bottom: -10px;
                left: 10px;
                right: -10px;
                background-image: url('../images/rectangle_11.png');
            }
        }
    }

    &.card-primary {
        background: transparent;
        text-align: left;

        .card-img-top-box-shadow {
            position: relative;

            .card-img-top {
                margin-bottom: 1.25rem;
                transition: transform 0.5s ease;
            }
        }

        .card-body {
            padding: 0;

            .card-subtitle {
                font-family: $font-family-base;
                font-size: .75rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1rem;
                letter-spacing: 1.2px;
                text-transform: uppercase;
                margin-bottom: .75rem;
            }

            .card-title {
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 600 !important;
                line-height: 1.75rem;
                color: $secondary;
            }
        }

        &.card-small {
            .card-img-top {
                aspect-ratio: 1 / 1;
                object-fit: cover;
            }

            .card-body {

                .card-title {
                    font-size: 1.25rem;
                    margin-bottom: 0;
                }
                .card-text {
                    font-size: .9375rem;
                }
            }
        }
    }
    
    &.card-border-mobile {

        @media screen and (max-width: 991px) {
            border-bottom: solid 1px $grey-40; 
            padding-bottom: 1.5rem; 
            margin-bottom: 0.5rem;
        }

    }
}

.card-link {
    text-decoration: none;
    img {
        z-index: 999;
    }

    &:hover img {
       transform: scale(1.015);
    }

    &:hover,
    &:focus {
        .card {
            &.card-primary {
                .card-img-top-box-shadow {

                    &::before {
                        content: '';
                        position: absolute;
                        height: .375rem;
                        bottom: .9375rem;
                        left: .375rem;
                        right: -0.375rem;
                        background-color: $red-500;
                        background-image: url('../images/rectangle_11.png');
                        z-index: 100;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        width: .375rem;
                        bottom: 1.25rem;
                        top: .375rem;
                        right: -0.375rem;
                        background-color: $red-500;
                        background-image: url('../images/rectangle_11.png');
                        z-index: 100;
                    }

                    .card-img-top {
                       
                    }
                }

                .card-body {
                    .card-subtitle {
                        color: $blue-400;
                    }
                }
            }

            &-feature-single {
                .intro-text {
                    &::after {
                        background-color: $blue-400;
                    }

                    &::before {
                        opacity: 1 !important;
                        transform: translate(30%, -50%) !important;
                    }
                }
            }

            &::before {
                opacity: 1 !important;
            }
        }
    }

    .card {
        &-feature-single {
            padding: 3rem;
            position: relative;
            height: 100%;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 50%;
                /* Adjust the width as needed */
                background-image: url('../images/feature-single.png');
                background-size: cover;
                background-position: center right;
                opacity: 0.5;
                transition: 0.3s;

                /* Adjust the opacity as needed */
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }

            .card-body {
                position: relative;
                padding: 0;
                z-index: 1;

                .intro-text {
                    &::before {
                        content: "\F135"; // Unicode value for "arrow-right" icon in Bootstrap Icons
                        font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
                        vertical-align: -0.125em;
                        margin-left: 8px;
                        -ms-transform: translate(-50%, -50%);
                        transform: translate(0, -50%);
                        position: absolute;
                        right: 0;
                        top: 50%;
                        opacity: .2;
                        transition: 0.3s;

                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.bg-primary,
.bg-secondary {
    .card {
        &.card-primary {
            .card-body {
                color: $white;
            }
        }
    }

    .card-link {
        text-decoration: none;
        display: block;

        &:hover,
        &:focus {
            .card {
                &.card-primary {
                    .card-img-top-box-shadow {
                        &::before {
                            content: '';
                            position: absolute;
                            height: 6px;
                            bottom: 14px;
                            left: 6px;
                            right: -6px;
                            background-color: $red-500;
                        }

                        .card-img-top {}
                    }

                    .card-body {
                        .card-subtitle {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}