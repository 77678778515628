:root {
    --icon-plus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23144D9C'%3E%3Cpath d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2z'/%3E%3C/svg%3E");
    --icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23144D9C' viewBox='0 0 16 16'%3E%3Cpath d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8z'/%3E%3C/svg%3E");
    --icon-plus-hover: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233164EF'%3E%3Cpath d='M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2z'/%3E%3C/svg%3E");
    --icon-minus-hover: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233164EF' viewBox='0 0 16 16'%3E%3Cpath d='M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8z'/%3E%3C/svg%3E");
}

.accordion-button {
    font-weight: 600;
    color: $primary;
    line-height: 1.5rem;
    font-size: 1.25rem;
    border-top: 1px solid $grey-30;
    /* Example color */

    &:not(.collapsed) {

        &:hover,
        &:focus {
            color: $blue-400;
            /* Darker text color on hover */
        }
    }

    &:hover,
    &:focus {
        color: $blue-400;
    }

    &::after {
        /* Hide the default arrow icon */
        display: none;
    }

    &::before {
        /* Insert custom icon using ::before pseudo-element */
        content: "";
        display: inline-block;
        width: 1.25rem;
        /* Match the default arrow size */
        height: 1.25rem;
        margin-left: -1.25rem;
        /* Align icon inside button */
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        position: absolute;
        right: 0;
        color: $primary;
    }

    &::before {
        /* Base icon setup with blue icon */
        background-image: var(--icon-plus);
    }

    &:not(.collapsed)::before {
        /* Use blue minus icon for expanded state */
        background-image: var(--icon-minus);
    }

    /* Hover/Focus state for both collapsed and expanded */
    &:hover::before,
    &:focus::before {
        background-image: var(--icon-plus-hover);
    }

    &:not(.collapsed):hover::before,
    &:not(.collapsed):focus::before {
        /* Use red minus icon for expanded state when hovered/focused */
        background-image: var(--icon-minus-hover);
    }
}