.pagination {
    li {
        &:first-child {
            a {
                position: relative;
                text-indent: -9999px;
                /* Hide the text */
                display: inline-block;
                /* Allows the link to be sized by its content */
                padding: 0 27px;
                line-height: 46px;

                &::before {
                    content: "\F12C"; // Unicode value for "arrow-right" icon in Bootstrap Icons
                    font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 1rem;
                    /* Icon width */
                    height: 2.375rem;
                    /* Icon height */
                    text-indent: 0;
                    font-size: 1.25rem;
                    padding-left: 1.0625rem;
                    color: $grey-50;
                }

                &:hover, &:focus {
                    &::before {
                        color: $blue-400;
                    }
                }
            }
        }

        &:last-child {
            a {
                position: relative;
                text-indent: -9999px;
                /* Hide the text */
                display: inline-block;
                /* Allows the link to be sized by its content */
                padding: 0 27px;
                line-height: 46px;

                &::after {
                    content: "\F135"; // Unicode value for "arrow-right" icon in Bootstrap Icons
                    font-family: "bootstrap-icons"; // Ensure this is the correct font family for Bootstrap Icons
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 1rem;
                    /* Icon width */
                    height: 2.375rem;
                    /* Icon height */
                    text-indent: 0;
                    font-size: 1.25rem;
                    padding-left: 1.0625rem;
                    color: $grey-50;
                }

                &:hover, &:focus {
                    &::after {
                        color: $blue-400;
                    }
                }
            }
        }
    }
}