// Overwrite Bootstrap's default variables or define your own global variables here.

// Colors

// Neutral
$white: #ffffff;
$grey-10: #F8F9FA;
$grey-20: #E3E5E8;
$grey-30: #D9DDE2;
$grey-40: #C0C6CE;
$grey-50: #8993A1;
$grey-60: #687382;
$grey-70: #404959;
$grey-80: #2C343F;
$grey-90: #1D2430;
$grey-100: #090E16; // primary text

// Blue
$primary: #144D9C; // Example primary color
$secondary: #0B2850; // Example secondary color
$blue-400: #3164EF;
$blue-500: #144D9C; // primary brand color
$blue-900: #0B2850;

// Red
$danger: #C41230;
$red-400: #F33757;
$red-500: #C41230; // primary brand color
$red-600: #B20824;

// Typography
$font-family-base: 'IBM Plex Sans', sans-serif;
$font-size-base: 1rem; // Base font size
$headings-font-family: 'Inter', sans-serif;
$headings-font-weight: 900;
$headings-color: $blue-900;
$body-color: #090E16;
$display-font-weight: 900 !important;

// Tables
$table-cell-padding-y: .75rem;
$table-bg: transparent;
$table-border-color: $grey-30;
$table-hover-bg: $white;
$table-color: #090E16;
$input-placeholder-color: $grey-50;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

// Buttons
$btn-font-weight: 400;
$btn-border-radius: 22.5rem !important;
$btn-padding-x: 1.25rem;
$btn-padding-y: 0.75rem;
$btn-border-width: 0.125rem !important;
$btn-font-size: 0.938rem !important;
$btn-padding-y-lg: 1.5rem;
$btn-padding-x-lg: 1.5rem;
$btn-font-size-lg: .9375rem;

// Forms
$input-border-radius: 0;
$input-padding-y: 8px;
$input-padding-x: 8px;
$input-border-color: #D9DDE2;
$input-bg: #F3F4F5;

// Grid and Layout
$grid-columns: 12;
$grid-gutter-width: 30px;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Accordion
$accordion-button-color: $primary;
$accordion-button-bg: transparent;
$accordion-button-active-bg: transparent;
$accordion-button-active-color: $primary;
$accordion-border-radius: 0;
$accordion-padding-x: 0;
$accordion-border-width: 0;
$accordion-padding-y: 1rem;

// Breadcrumbs
$breadcrumb-font-size: .8125rem;
$breadcrumb-active-color: $secondary;


// Cards
$card-border-radius: 0;
$card-border-width: 0;
$card-title-color: $primary;
$card-subtitle-color: $grey-50;

// Pagination
$pagination-border-radius: 22.5rem;
$pagination-padding-y: .6875rem;
$pagination-padding-x: 1.125rem;
$pagination-border-color: $grey-30;
$pagination-color: $primary;
$pagination-active-color: $white;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;
$pagination-hover-color: $blue-400;
$pagination-hover-bg: $grey-20;
$pagination-hover-border-color: $grey-20;

// Alerts
$alert-border-radius: 0;
$alert-border-width: 0;