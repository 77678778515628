body {
    font-family: Arial, sans-serif;
}

.search-container-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.search-container {
    display: flex;
    align-items: center;
    border: .0625rem solid $grey-40;
    border-radius: 3.125rem;
    padding: .3125rem .625rem;
    transition: width 0.3s ease;
    width: 8.4375rem; /* Initial width for desktop */
    overflow: hidden;
    
    form {
        display: flex;
        width: 100%;
    }
}

.search-input {
    border: none;
    outline: none;
    flex: 1;
    padding: .3125rem;
    transition: width 0.3s ease;
    width: 5.3125rem;
}

.search-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: $red-500;
}

.close-button {
    display: none;
    font-size: 1.5em;
    color: $grey-40;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: .625rem;
}

/* Expanded state */
.search-container.expanded {
    width: 19.6875rem; /* Expanded width for desktop */
}

.search-container-wrapper.expanded .close-button {
    display: inline;
}

/* Mobile view */
@media (max-width: 1280px) {
    .search-container {
        width: 100%;
    }

    .close-button {
        display: none;
    }

    .search-container-wrapper.expanded .close-button {
        display: none;
    }
}
