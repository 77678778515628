// Define the container which will hold both images
.image-container {
  position: relative;
  margin-top: 3rem;

  // Set the width and height as needed, or let it be determined by content
  // Define the base image
  .base-image {
    display: block;
    width: 100%;
    padding-right: 20%;
    position: relative;

    &::before {
      content: '';
      height: 10px;
      position: absolute;
      bottom: -0.625rem;
      left: .625rem;
      right: 20%;
      background-color: $red-500;
      background-image: url('../images/rectangle_11.png');
      background-repeat: repeat;
    }

    &::after {
      content: '';
      width: .625rem;
      position: absolute;
      bottom: -0.625rem;
      top: .625rem;
      right: calc(20% - .625rem);
      background-color: $red-500;
      background-image: url('../images/rectangle_11.png');
      background-repeat: repeat;
    }
  }

  // Define the overlapping image
  .overlapping-image {
    padding-left: 20%;
    margin-top: -20%;
    position: relative;
  }
}