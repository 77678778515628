/** top destinations menu **/
.top-destinations-container {
    max-width: 82.5rem;
    padding: 0 .9375rem;
    margin: auto;
}

.top-destinations-nav {
    background-color: $primary;
    color: $white;

    @media (min-width: 1281px) {
        display: flex;
        flex-wrap: wrap;
    }

}

.top-destinations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 0;

    @media (max-width: 1280px) {
        padding: .625rem .75rem .625rem;
    }

    @media (min-width: 1281px) {
        flex-grow: 0;
        width: auto;
    }

    span.top-destinations-title {
        text-transform: uppercase;
        font-weight: 700;
        color: $grey-40;
        font-size: .8125rem;
        letter-spacing: .075rem;

        @media (max-width: 1280px) {
            color: $white;
        }

        &::after {
            @media (min-width: 1281px) {
                content: ":";
            }
        }
    }

    .menu-toggle {
        background: none;
        border: none;
        color: $white;
        font-size: 1.5em;

        @media (min-width: 1281px) {
            display: none;
        }
    }

    .plus-icon {
        display: inline;
        font-size: 1.5rem;
    }

    .minus-icon {
        display: none;
        font-size: 1.5rem;
    }
}


.menu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    transition: max-height 0.3s ease-out;

    @media (min-width: 1281px) {
        flex-grow: 1;
        width: auto;
        background-image: url('../images/top-nav-bg.svg');
        background-position: bottom right;
        background-repeat: no-repeat;
        background-size: 200px;
    }

    li {
        padding: .625rem 0;

        a {
            color: $white;
            text-decoration: none;
            display: block;
            padding: .25rem .875rem;
            border-radius: 22.5rem;
            font-size: .8125rem;

            &:hover,
            &:focus {
                @media (min-width: 1280px) {
                    background: $blue-400;
                }
            }
        }
    }
}






/* Desktop view */
@media (min-width: 768px) {
    .menu {
        max-height: none;
        display: flex;

        li {
            padding: .625rem 0;
        }
    }

    .menu-toggle {
        display: none;
    }
}

/* Mobile view */
@media (max-width: 1280px) {
    .menu {
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
    }

    .menu-toggle {
        display: inline;
    }

    .menu-opened .menu {
        max-height: 62.5rem;
        /* Arbitrary value to allow full expansion */
    }

    .menu-opened .plus-icon {
        display: none;
    }

    .menu-opened .minus-icon {
        display: inline;
    }
}