.header-wrap {
  width: 100%;
  display: flex;
  justify-content: center; /* Center the content horizontally */
}

.fixed-container {
  position: fixed;
  top: 0;
  z-index: 1001; /* Ensure it stays above other content */
  background-color: #ffffff; /* Ensure it has a background color */
  height: 64px;
  margin: 0 auto; /* Center the container */
}

body {
  padding-top: 64px; /* Adjust this to match the height of the fixed header */
}

.mobile-menu-container {
  display: flex;
  align-items: center;
  height: 64px; /* Ensure it maintains its height */
}

.mobile-menu-left-column {
  flex: 1; /* Fills the remaining width */
}

.mobile-logo-link {
  display: block;
  max-width: 9.25rem;
}

.mobile-logo {
  max-width: 9.25rem;
  margin: .8125rem 0;
}

.mobile-menu-right-column {
  flex-shrink: 0; /* Prevents shrinking */
}

.mobile-menu-toggle {
  font-size: .8125rem;
  padding: .5rem 1rem;
  border: none;
  border-radius: 22.5rem;
  display: flex;
  background-color: #ffffff;
  color: $secondary;
}

.mobile-menu-toggle::after {
  content: '';
  width: 1.5rem;
  height: 1.5rem;
  margin-left: .5rem;
  background-image: url('../images/menu.svg');
}

.mobile-menu-toggle.open {
  background-color: #cccccc;
}

.mobile-menu-toggle.open::after {
  background-image: url('../images/close-x.svg');
}

/* Hide mobile menu on desktop */
@media (min-width: 1281px) {
  .fixed-container {
    display: none;
  }

  body {
    padding-top: 0; /* Remove padding on desktop */
  }
}

/* Hide header-flex div on mobile */
@media (max-width: 1280px) {
  .header-flex {
    display: none;
  }
}

/* Show header-flex div when the mobile menu is open */
.header-flex.open {
  display: block;
  position: fixed;
  top: 64px; /* 64px from the top */
  left: 0;
  width: 100%;
  height: calc(100% - 64px);
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.8); /* Adjust background color as needed */
  z-index: 1000;
  background-color: #ffffff;
}

/* Prevent body scrolling when menu is open */
body.menu-open {
  overflow: hidden;
}
