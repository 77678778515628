#pills-tab.nav-pills {
    @include media-breakpoint-down(md) {
        gap: .9375rem !important;
    }

    .nav-link {
        padding-left: 0;
        padding-right: 0;
        border-radius: 0 !important;
        border-bottom: solid 4px transparent;
        font-size: 1.25rem !important;
        font-weight: 600;

        &:hover,
        &:focus {
            border-bottom: solid 4px $light;
        }

        &.active {
            border-bottom: solid 4px $secondary;
            background-color: transparent;
            color: $primary;
        }
    }
}

.bg-primary,
.bg-secondary {
    #pills-tab.nav-pills {
        .nav-link {
            color: rgba(255, 255, 255, 0.70);
            border-bottom: solid 4px rgba(255, 255, 255, 0.20);

            &:hover,
            &:focus {
                color: $light;
                border-bottom: solid 4px $light;
            }

            &.active {
                color: $white;
                border-bottom: solid 4px $blue-400 !important;

                &:hover {
                    border-bottom: solid 4px $blue-400 !important;
                }
            }
        }
    }
}

.tabs-bg-pattern {
    background-image: url('../images/pattern-blue.png');
    background-size: 80px;

    .tabs-secondary-bg-img {
        background-color: rgba(11, 40, 80, 0.7);
        background-repeat: no-repeat;
        background-position: (100% 0);

        @include media-breakpoint-up(md) {
            background-image: url('../images/tab-accent-bg.png');
            background-size: contain;
            .btn {
                width: 100%;
            }
        }
    }
    &.tabs-bg-pattern-red {
        background-image: url('../images/pattern-red.png');
        .tabs-secondary-bg-img {
            background-color: rgba(178, 8, 36, 0.7);
        }
    }
}

.tabs-minimal-padding {
    padding: 48px;

    @media screen and (max-width: 768px) {
        padding: 48px 24px;
        margin: 0 -15px;
    }
}

.tro-container ul {
    padding-top: 32px; /* Add desired padding */
}