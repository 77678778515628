.programListing-sidebar-toggle {
    .programListing-sidebar-toggle-header {
        @media (min-width: 992px) {
            position: relative;
            margin-bottom: calc(1.5rem + 1.375rem);
            font-family: $headings-font-family;
            font-weight: 600;
            color: $secondary;
            line-height: 28px;
            font-size: 24px;

            &::after {
                content: "";
                width: 1.875rem;
                height: .25rem;
                background-color: $grey-40;
                transform: skewX(-30deg);
                display: block;
                position: absolute;
                left: 0;
                bottom: -1.125rem;
            }
        }

        @media (max-width: 991px) {
            padding: 12px 16px;
            position: relative;
            font-family: $headings-font-family;
            font-weight: 600;
            color: $white;
            background-color: $secondary;
            line-height: 24px;
            font-size: 20px;

            &::after {
                content: "-";
                font-size: 24px;
                position: absolute;
                right: 12px;
                top: 50%;
                color: $white;
                transform: translate(-50%, -50%);
            }
        }

        &.collapsed {
            @media (max-width: 991px) {
                &::after {
                    content: "+";
                }
            }
        }
    }

    .programListing-sidebar-toggle-content {
        @media (max-width: 991px) {
            padding: 28px 16px;
            background-color: $grey-30;

            &.collapsed {
                display: none;
            }
        }
    }
}


.programListing-results {
    margin-bottom: 40px;
    font-size: .9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    display: block;
    border-bottom: solid 1px $grey-30;
    padding: 8px 0px;

    span {
        font-weight: 500;
    }

    @media (max-width: 768px) {
        padding-top: 32px;
    }

}

ul.program-listing {
    list-style: none;
    padding: 0;

    li {
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
        color: inherit;
        display: block;
        background-color: #f4f5f7;
    }

    .certificate-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 20px;
        background-color: #f8f9fa;
        position: relative;
    }

    .left-column {
        font-size: 1.25rem;
        font-weight: 600;
        color: $primary;
        position: relative;
        line-height: 1.5rem;
        padding-left: 22px;
        /* Add padding to leave space for the pseudo-element */
    }

    .left-column::before {
        content: "";
        width: 12px;
        height: 12px;
        background-color: #F57E3C;
        /* Default color */
        border-radius: 3px;
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 0;
    }

    .right-column {
        font-size: .9375rem;
        color: #000;
        white-space: nowrap;
        font-weight: 500;
        line-height: 1.5rem;
    }

    a.orange .left-column::before {
        background-color: #F57E3C;
    }

    a.orange .certificate-item:hover {
        background-color: #FDE9DC;
    }

    a.purple .left-column::before {
        background-color: #7B55D7;
    }

    a.purple .certificate-item:hover {
        background-color: #E5DDFC;
    }

    a.red .left-column::before {
        background-color: #F33757;
    }

    a.red .certificate-item:hover {
        background-color: #FEE0E6;
    }

    a.blue .left-column::before {
        background-color: #3164EF;
    }

    a.blue .certificate-item:hover {
        background-color: #E1E8FC;
    }

    a.green .left-column::before {
        background-color: #0ABC9C;
    }

    a.green .certificate-item:hover {
        background-color:  #E3F4F1;
    }

    @media (max-width: 768px) {
        .certificate-item {
            flex-direction: column;
            align-items: flex-start;
        }

        .right-column {
            margin-top: 8px;
            text-wrap: wrap;
            margin-left: 22px;
        }
    }

    /* Ensure that the list items are initially visible */
    .program-list-item {
        transition: all 1s ease; /* Animates all properties smoothly */
        opacity: 1;
        height: auto;
        margin: 10px 0;
    }

    /* When hiding an item, animate opacity and height to make it disappear smoothly */
    .program-list-item.hide {
        opacity: 0;
        height: 0;
        margin: 0; /* Remove margin when hidden to avoid spacing */
        padding: 0;
        overflow: hidden;
    }
}

// sidebar

/* General container */
.programListing-sidebar {

    /* Search Bar */
    .search-bar {
        display: flex;
        align-items: center;
        position: relative;
    }

    #searchInput {
        width: 100%;
        padding: 8px 16px;
        border-radius: 30px;
        border: 1px solid $grey-50;
        font-size: .9375rem;
        background-color: #F3F4F5;
    }

    .search-icon {
        position: absolute;
        right: 15px;
        font-size: 1rem;
        color: #090E16;
    }

    /* Dropdown */
    .dropdown-container {
        font-weight: 500;
        font-size: 18px;
        color: #000;
        line-height: 1.5rem;
        position: relative;

        label {
            margin-bottom: 12px;
        }
    }

    #areaOfStudy {
        width: 100%;
        padding: 12px 20px;
        border-radius: 30px;
        background-color: $primary;
        color: #fff;
        border: none;
        font-size: .9375rem;
        font-weight: 500;
        appearance: none;
        position: relative;
    }

    #areaOfStudy:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    }

    .dropdown-container::after {
        content: '\F229';
        /* Font Awesome down arrow */
        font-family: "bootstrap-icons";
        position: absolute;
        right: 20px;
        top: 50%;
        font-size: 12px;
        color: #fff;
        /* White arrow */
        transform: translateY(7px);
    }

    /* By Academic Credential Heading */
    .credential-heading {
        margin: 20px 0;
        font-weight: 500;
        font-size: 18px;
        color: #000;
        line-height: 1.5rem;
    }

    /* Custom Checkbox */
    /* Custom Checkbox */
    .custom-checkbox {
        margin-bottom: 15px;
    }

    .custom-checkbox label {
        font-size: .9375rem;
        font-weight: bold;
        color: #212529;
        cursor: pointer;
        position: relative;
        font-weight: 400;
    }

    .custom-checkbox input[type="checkbox"] {
        display: none;
    }

    .checkbox-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        margin-right: 10px;
        transform: translateY(9px);
        background-color: #F3F4F5;
        /* Default color when unchecked */
        border: solid 1px #D9DDE2;
    }

    /* Checkbox Checked State with Different Colors */
    .custom-checkbox input[type="checkbox"]:checked+.checkbox-icon.orange {
        background-color: #f47c26;
        border: solid 1px #f47c26;
    }

    .custom-checkbox input[type="checkbox"]:checked+.checkbox-icon.blue {
        background-color: #0b5ed7;
        border: solid 1px #0b5ed7;
    }

    .custom-checkbox input[type="checkbox"]:checked+.checkbox-icon.red {
        background-color: #e63946;
        border: solid 1px #e63946;
    }

    .custom-checkbox input[type="checkbox"]:checked+.checkbox-icon.purple {
        background-color: #7e5ab7;
        border: solid 1px #7e5ab7;
    }

    .custom-checkbox input[type="checkbox"]:checked+.checkbox-icon.green {
        background-color: #0ABC9C;
        border: solid 1px #0ABC9C;
    }

    /* Checkbox Checkmark */
    .custom-checkbox input[type="checkbox"]:checked+.checkbox-icon::before {
        content: '\F26E';
        font-family: "bootstrap-icons";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.5rem;
        color: white;
        /* White checkmark */
    }

    .custom-checkbox input[type="checkbox"]:focus+.checkbox-icon {
        outline: 3px solid rgba(0, 123, 255, 0.25);
        /* Accessible focus outline for checkboxes */
    }

    /* Small Text Below Checkbox */
    .custom-checkbox small {
        display: block;
        font-size: .8125rem;
        color: #6c757d;
        margin-left: 40px;
        /* Aligned under checkbox */
        font-weight: 400;
    }

    /* Responsive Design */
    @media (max-width: 768px) {
        #areaOfStudy {
            padding: 10px;
        }
    }

}