.stat-wrapper {
    position: relative;

    img {
        aspect-ratio: 2 / 4;
        object-fit: cover;
        width: 100%;
    }

    .stat-content {
        background-color: $red-500;
        padding: 24px;
        position: absolute;
        transform: translate(50%, 50%);
        max-width: 14.6875rem;
        background-image: url('../images/rectangle_11.png');

        .stat-heading {
            font-size: 60px;
            line-height: normal;
            font-weight: 900;
            font-family: $headings-font-family;
            color: $white;
            display: block;
        }

        .stat-body {
            font-family: $font-family-base;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            color: $white;
            display: block;
        }
    }

    &.bottom {
        @media (max-width: 991px) {
            img {
                padding-top: 88px;
                padding-left: 75px;
                aspect-ratio: 2 / 3;
            }
        }

        .stat-content {
            bottom: 0;
            right: 50%;

            @media (max-width: 991px) {
                top: 0;
                left: 0;
                min-width: 235px;
                transform: none;
                bottom: initial;
            }
        }
    }

    &.top {
        @media (max-width: 991px) {
            margin-top: -65px;

            img {
                padding-right: 75px;
                aspect-ratio: 2 / 3;
            }
        }

        @media (min-width: 992px) {
            margin-top: 10rem;
        }

        .stat-content {
            top: 0;
            left: 0;
            transform: translate(-50%, -65%);

            @media (max-width: 991px) {
                top: 50%;
                left: 100%;
                min-width: 235px;
                transform: translate(calc(-100% + 15px), -50%);
            }
        }
    }

    &.left {
        @media (min-width: 992px) {
            margin-top: 4.25rem;
        }

        @media (max-width: 991px) {
            margin-top: -65px;

            img {
                padding-left: 75px;
                aspect-ratio: 2 / 3;
            }
        }

        .stat-content {
            top: 50%;
            left: 0;
            transform: translate(-20%, 50%);

            @media (max-width: 991px) {
                bottom: 23px;
                top: initial;
                left: 0;
                min-width: 235px;
                transform: none;
            }
        }
    }
}

.stats-secondary {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 2rem;

    @media (max-width: 768px) {
        flex-direction: column;
    }

    li {
        flex: 1;
        background-color: $red-500;
        padding: 24px;
        text-align: center;
        background-image: url('../images/pattern-red.png');
        background-size: 80px;
        background-repeat: repeat;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-size: 48px;
            font-weight: 900;
            line-height: 52px;
            color: $white;
        }

        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            color: $white;
        }
    }
}

.stats-bg-accent {
    background-image: url('../images/red-accent.svg');
    background-position: left 30px;
    background-repeat: no-repeat;
    background-size: 722px 500px;

    @media screen and (max-width: 768px) {
        background-size: 288px 240px;
        background-position: (50% 5%);

    }
}