// Contains base styles, like resets, typography rules, and body background.
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Regular.woff2') format('woff2'),
         url('../fonts/Gotham-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.woff2') format('woff2'),
         url('../fonts/Gotham-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

img {
    max-width: 100%;
    height: auto;
}

.list-two-col {
    list-style-type: none;
    margin: 0;
    padding: 0;
    
    @include media-breakpoint-up(lg) {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
    @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
    }
    li {
        margin-bottom: 15px;
        @include media-breakpoint-down(lg) {
            display: block;
            a {
                display: block;
                text-align: left;
            }
        }
    }
}

.list-inline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    gap: 15px;
    @include media-breakpoint-down(md) {
        flex-direction: column;
    }
}

// Background red
.bg-red {
    background-color: $red-500 !important;
}
.bg-pattern-blue {
    background-image: url('../images/pattern-blue.png');
    background-size: 80px;
}

.bg-pattern-red {
    background-image: url('../images/pattern-red.png');
    background-size: 80px;
}

// Custom gradient
.bg-light.bg-gradient {
    background: linear-gradient(to bottom, $light, transparent) !important;
}

.hidden {
    display: none;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.roane--news-and-events .card-link:hover h5 {
    text-decoration: underline;
} 

span.form-label {
    display: block;
}