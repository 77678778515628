/* Base styles for the container and sections */
.header-flex {
    display: flex;
    flex-wrap: wrap;
}

.section {
    width: 100%;
    /* Default width for mobile */
    box-sizing: border-box;
    /* Space between sections */
}

.section3,
.section4 {
    padding: 15px 0;
}

.middle-sections {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 15px;
}

/* Desktop styles using a media query */
@media (min-width: 1281px) {

    /* Adjust the min-width as needed for your breakpoint */
    .section1 {
        width: 100%;
    }

    .section2 {
        flex-grow: 1;
        width: auto;
        display: flex;
        align-items: center;

        /* Make it flexible */
        .custom-logo {
            height: 55px;
            margin: 15px 0;
        }
    }

    .section3,
    .section4 {
        flex-grow: 0;
        width: auto;
        display: flex;
        align-items: center;
        /* Allow them to shrink to fit content */
    }

    .section5 {
        width: 100%;
        /* Full width */
    }
}

/* Mobile styles to reorder sections */
@media (max-width: 1280px) {

    /* Adjust max-width as needed for your breakpoint */
    .section2 {
        order: 2;
        display: none;
    }

    .section1 {
        order: 0;

        &.bg-primary {
            background-color: $white !important;
        }
    }

    .section4 {
        order: 1;
    }

    .section5 {
        order: 2;
        @media (max-width: 1280px) {
            border-bottom: solid .0625rem $grey-40;
            padding: 0 0 1rem;
            margin-bottom: 1rem;
        }
    }

    .section3 {
        order: 3;
    }

    .middle-sections {
        flex-direction: column;
    }
}