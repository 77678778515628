.request-info-menu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.request-info-menu-item {
    margin-right: 1.25rem;
}

.request-info-menu-item a {
    text-decoration: none;
    color: $secondary;
    /* Adjust this color as needed */
    font-size: 13px;
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    border-radius: 22.5rem;

    /* Adjust this value as needed */
    @media (max-width: 1280px) {
        font-size: 1.125rem;
    }
}

.request-info-menu-item a::before {
    content: '';
    display: inline-block;
    margin-right: .3125rem;
    color: $grey-50;
}

.request-info-menu-item a:hover {
    background-color: #E0E0E0;

    /* Adjust this color as needed */
    &::before {
        color: $primary;
    }
}

.request-info a::before {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;
    background-image: url('../images/request-info-hover.svg');
    background-position: center left;
    background-size: cover;
}

.request-info a:hover::before {
    background-position: center right;
}

.schedule-tour a::before {
    content: '\F3E7';
    /* Bootstrap geo-alt icon */
    font-family: "bootstrap-icons";
}

.apply a::before {
    content: '';
    width: 1rem;
    height: 1rem;
    margin-right: .5rem;
    background-image: url('../images/apply-hover.svg');
    background-position: center left;
    background-size: cover;
}

.apply a:hover::before {
    background-position: center right;
}

@media (max-width: 1280px) {
    .request-info-menu {
        flex-direction: column;
    }

    .request-info-menu-item {
        margin-bottom: .625rem;
    }

    .request-info-menu-item:last-child {
        margin-bottom: 0;
    }
}