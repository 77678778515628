/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container for the video section */
.video-section {
  position: relative;
  width: 100%;
  height: 762px;
  /* Full viewport height */
  overflow: hidden;
  background: url('../images/background-video-poster.jpg') center center / cover no-repeat;
  background-color: $blue-500;

  @media (min-width: 769px) {
    &::before {
      content: '';
      width: 216px;
      height: 427px;
      background-image: url('../images/home-left3x.svg');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 999;
    }
  }

  @media (max-width: 768px) {
    height: 318px;
  }
}

/* Styling the video to cover the container */
.video-section video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  display: block;
}

/* Gradient overlay */
.video-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  /* Adjust height as needed */
  background: linear-gradient(to top, rgba(8, 16, 28, 0.96), rgba(4, 22, 53, 0.4), transparent);
  pointer-events: none;
  /* Allows clicks to pass through */
  z-index: 2;
}

/* Play/Pause Button */
.video-button {
  position: absolute;
  bottom: 77px;
  right: 20px;
  width: 40px;
  /* Updated width */
  height: 40px;
  /* Updated height */
  background-color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: background-color 0.3s ease; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  z-index: 3;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease;
  /* Duration and easing of fade-in */
}

.video-button:hover {
  background-color: #f0f0f0;
}

/* Play Icon */
.play-icon {
  width: 0;
  height: 0;
  border-left: 10px solid #C41230;
  /* Adjusted size */
  border-top: 6px solid transparent;
  /* Adjusted size */
  border-bottom: 6px solid transparent;
  /* Adjusted size */
  transition: all 0.3s ease;
}

/* Pause Icon */
.pause-icon {
  display: none;
  width: 12px;
  /* Adjusted size */
  height: 12px;
  /* Adjusted size */
  position: relative;
}

.pause-icon::before,
.pause-icon::after {
  content: '';
  position: absolute;
  width: 3px;
  /* Adjusted size */
  height: 12px;
  /* Adjusted size */
  background-color: #C41230;
  top: 0;
}

.pause-icon::before {
  left: 0;
}

.pause-icon::after {
  right: 0;
}

/* Active state for pause icon */
.video-button.paused .play-icon {
  display: none;
}

.video-button.paused .pause-icon {
  display: block;
}

/* Hide video and show placeholder image on small screens */
@media (max-width: 768px) {
  .video-section video {
    /*display: none; */
  }

  .video-section {
    background: url('https://images.pexels.com/photos/1251861/pexels-photo-1251861.jpeg') center center / cover no-repeat;
  }

  .video-button {
    /* display: none; */
    /* Hide play/pause button if video is not displayed */
  }
}

.video-relative {
  position: relative;

  .video-content {
    background-color: rgba(11, 40, 80, 0.7);
    position: absolute;
    z-index: 999;
    padding: 32px;
    margin-bottom: 32px;
    bottom: 0;
    width: 50%;

    @media (max-width: 1280px) {
      width: 90%;
    }

    @media (max-width: 768px) {
      background-color: $secondary;
      width: 100%;
      position: relative;
      padding: 40px 32px;
      margin-right: -15px;
      margin-left: 15px;
      z-index: 1;
    }

    .video-heading {
      color: $white;
    }

    p {
      color: $white;
    }

    .video-inline-cta {
      list-style-type: none;
      padding: 0;
      display: inline-flex;
      flex-direction: row;
      gap: 20px;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  .container {
    @media (max-width: 768px) {
      margin-top: -60px;
      position: relative;
      z-index: 999;
      background-image: url('../images/home-left3x.svg');
      padding-top: 30px;
      background-repeat: no-repeat;
    }
  }
}

.disable-video-background-image {
  height: 762px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @media (min-width: 769px) {
    &::before {
      content: '';
      width: 216px;
      height: 427px;
      background-image: url('../images/home-left3x.svg');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 999;
    }
  }

  @media (max-width: 768px) {
    height: 318px;
  }
}